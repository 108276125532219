<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.questionGroup.menu"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.questionGroup.list.title"
        ></app-i18n>
      </h1>

      <app-question-group-list-toolbar></app-question-group-list-toolbar>
      <app-question-group-list-filter></app-question-group-list-filter>
      <app-question-group-list-table></app-question-group-list-table>
    </div>
  </div>
</template>

<script>
import QuestionGroupListFilter from '@/modules/question-group/components/question-group-list-filter.vue';
import QuestionGroupListTable from '@/modules/question-group/components/question-group-list-table.vue';
import QuestionGroupListToolbar from '@/modules/question-group/components/question-group-list-toolbar.vue';

export default {
  name: 'app-question-group-list-page',

  components: {
    [QuestionGroupListFilter.name]: QuestionGroupListFilter,
    [QuestionGroupListTable.name]: QuestionGroupListTable,
    [QuestionGroupListToolbar.name]: QuestionGroupListToolbar,
  },
};
</script>

<style></style>
